import { render, staticRenderFns } from "./MyFedrampAccountsTableV2.vue?vue&type=template&id=47db1651&scoped=true&"
import script from "./MyFedrampAccountsTableV2.vue?vue&type=script&lang=js&"
export * from "./MyFedrampAccountsTableV2.vue?vue&type=script&lang=js&"
import style0 from "./MyFedrampAccountsTableV2.vue?vue&type=style&index=0&id=47db1651&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47db1651",
  null
  
)

export default component.exports