<template>
  <div>
    <div class="row">
      <div class="col-12">        
        <div v-if="!getAllCorporateAccountsLoading" class="all-accounts-table-container">
          <v-client-table class="table table-hover" v-model="getAllCorporateAccounts" :columns="allAccountColumns" :options="options" ref="accountTable">                        
            <span slot="accountCustomName" slot-scope="props">
              <button title="Open account details" class="btn-link" @click="toggleAccountDetails(props.row)">
                <span class="account-text">
                  <span class="child-toggler-icon">
                    <i class="fas fa-angle-down" v-if="detailsDisplayed.includes(props.row.accountId)" ></i> 
                    <i class="fas fa-angle-right" v-else></i> 
                  </span>
                  {{props.row.accountCustomName.toLowerCase() || "-"}}
                </span>
              </button>
            </span>
            <span slot="environment" slot-scope="props">
              <span class="available-roles" :title="props.row.environment">{{props.row.environment.toUpperCase()}}</span>
            </span>
            <template v-slot:accountId="props">
              <button class="btn-link" title="Click to copy Id" @click="copyText(props.row.accountId)">
                <span class="account-text">
                  {{props.row.accountId}}
                </span>
              </button>
            </template>
            <span slot="roles" slot-scope="props">
              <span v-if="getMyAccount(props.row)" class="available-roles">{{parseRole(getMyAccount(props.row), true)}}</span>
              <span v-else class="available-roles">None</span>
            </span>
            <span slot="action" slot-scope="props">
<!--              <a v-if="getMyAccount(props.row)" class="access-link" @click="accessActionClick(props.row)"  v-on:click.middle="accessActionClick(props.row)"-->
<!--                :href="parseLoginUrl(getMyAccount(props.row))" target="_blank">-->
<!--                  Launch-->
<!--              </a>-->
              <button v-if="!getMyAccount(props.row)" title="Request access" class="btn-link" @click="showAccessRequestModal(props.row)">
                Request access
              </button>
              <button v-if="getMyAccountRoles(getMyAccount(props.row))" title="Request access" class="btn-link" @click="showAccessRequestModal(getMyAccount(props.row))">
                Request role
              </button>
              <em v-if="getHasAdminRole && !hasValidAppId(props.row)" class="fa fa-exclamation app-id-notice" 
                :title="'Invalid App ID ('+props.row.azureAppId+')'"></em>
            </span>
            <template v-slot:child_row="props">
              <account-details
                  :data="props.row"
              />
            </template>
          </v-client-table>
          <div v-if="isToastVisible" class="toast">
            {{ toastMessage }}
          </div>
        </div>
        <div class="loading-container" v-else>
          <loading-spinner  />
        </div>
      </div>
    </div>
    <transition name="access-request-modal">
      <access-request-modal
        v-if="displayAccessRequestModal"
        :account="accessRequestAccount"
        @close="closeAccessRequestModal"
      />
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import util from "@/util";
import LoadingSpinner from "@/components/LoadingSpinner";
import AccountDetails from "@/components/AccountDetailsV2.vue";
import * as C from "../constants.js";
import AccessRequestModal from '@/components/modal/AccessRequestModal.vue'

export default {
  name: "allCorporateAccountsTable",
  components: {
    LoadingSpinner,
    AccountDetails,
    AccessRequestModal
  },
  watch: {
    searchTerm() {
      console.log(this.searchTerm)
    }
  },
  computed: {
    ...mapGetters(["getAppUser", 
      "getAccounts", 
      "getCacheDataDate",
      "getTokenData",
      "getHasAdminRole",
      "getAllCorporateAccounts",
      "getAllCorporateAccountsLoading"]),
      searchTerm() {

        return this.$route.query.q;
      },
  },
  data: function () {
    return {
      C,
      allAccounts: [],
      allAccountColumns: ["accountCustomName", "environment", "accountId", "roles", "action"],
      options: {
        customFilters: [
          {
            name: "search",
            callback: function (row, query) {
              let q = query.toLowerCase();
              let name = row.accountCustomName.toLowerCase();
              let id = row.accountId.toLowerCase();
              return name.includes(q) || id.includes(q);
            },
          },
        ],
        skin: "table",
        headings: {
          favorite: "*",
          accountCustomName: "Name",
          environment: "Environment",
          accountId: "Account ID",
          roles: "Active roles",
          action: "Actions",
        },
        uniqueKey: "accountId",
        sortable: [ "accountCustomName", "environment", "accountId"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No corporate accounts to display",
        },
        headingsTooltips: {
          favorite : "Click to sort. Shift+Click to multi-sort.",
          accountId : "Click to sort. Shift+Click to multi-sort.",
          accountCustomName : "Click to sort. Shift+Click to multi-sort."
        },
        orderBy : {
          column: "accountCustomName"
        },
        perPageValues: [10,25,50],
        showChildRowToggler: false
      },
      isLoading: true,
      detailsDisplayed:[],
      displayAccessRequestModal: false,
      accessRequestAccount: null,
      isToastVisible: false,
      toastMessage: ''
    };
  },
  created() {
  },
  methods: {    
    ...mapActions(["addAlert"]),
    copyText(text){
      navigator.clipboard.writeText(text);
      this.showToast("Copied to clipboard");
    },
    showToast(message) {
      this.toastMessage = message;
      this.isToastVisible = true;

      setTimeout(() => {
        this.isToastVisible = false;
      }, 3000);
    },
    parseDate(date) {
      return util.parseDate(date)
    },
    parseRole(account, trim){
      return util.parseRole(account,trim)
    },
    hasValidAppId(item) {
      return util.hasValidAppId(item)
    },
    async toggleAccountDetails(row) {
      
      if (this.detailsDisplayed.includes(row.accountId)) {
        this.detailsDisplayed.splice(this.detailsDisplayed.indexOf(row.accountId), 1)
        this.trackEvent(C.ACTION_TYPES.HIDE_VIEW_DETAIL, this.$router.currentRoute.path+"?view=all-corporate-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      } else {
        this.detailsDisplayed.push(row.accountId)
        this.trackEvent(C.ACTION_TYPES.SHOW_VIEW_DETAIL, this.$router.currentRoute.path+"?view=all-corporate-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      }

      this.$refs.accountTable.toggleChildRow(row.accountId);
    },
    showAccessRequestModal(item) {   
      this.trackEvent(C.ACTION_TYPES.ACCESS_REQUEST_LINK, 
        this.$router.currentRoute.path+"?view=all-corporate-accounts",  
        `account_id=${item.accountId} name=${item.accountCustomName}`);
      
      this.displayAccessRequestModal = true
      this.accessRequestAccount = item
    },
    closeAccessRequestModal() {
      this.displayAccessRequestModal = false
      this.accessRequestAccount = null
    },
    getMyAccount(account) {
      return this.getAccounts.find(ele => {
        return ele.accountId === account.accountId
      })
    },
    getMyAccountRoles(account) {
      if(!account){ return false }
      return (util.parseRole(account).split(",")).length < 2
    },
  },
};
</script>
<style scoped>

.account-text{
  color:#5a5a5a;
  display:inline-flex;
}
.child-toggler-icon{
  font-size: 14px;
  margin-right: 8px;
  color:#5f60ff;
}
.cache-info-text {
  font-size: 14px;
}
.account-help-text {
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
.accounts-table-container {
  margin-top: 5px;
  font-size: 14px;
}
.access-link {
  font-weight: bold;
  padding-left: 6px;
}
.app-id-notice {
  color: red;
  font-size: 14px;
}
.sync-btn {
  border: none;
  background: transparent;
  color: #5f60ff;
  outline: none;
  margin-left: 5px;
  font-size: 14px;
}
.sync-btn:hover {
  color: #0056b3;
}
.accounts-toggle-button{
  float: left;
  margin-right: 15px;
}
.accounts-toggle-text{
  font-size: 14px;
  padding-top:2px;  
}
.access-request-modal-enter-active {
  transition: all .3s ease-in;
}
.access-request-modal-leave-active {
  transition: all .3s ease-out;
}
.access-request-modal-enter, .access-request-modal-leave-to {
  transform: scale(1.1);
  opacity: 0;
}
</style>
