<template>
  <div>
    <div class="row">
      <div class="col-12">        
        <div class="accounts-table-container">
          <v-client-table class="table table-hover" v-model="rows" :columns="columns" :options="options" ref="accountTable">
            <button
              slot="favorite"
              slot-scope="{row, update}"
              class="fav-btn"
              :class="row.favorite?'fas fa-star':'far fa-star'"
              @click="setFavorite(row); update(row.favorite)"
            ></button>
            <span slot="accountCustomName" slot-scope="props">
              <button title="Open Account Details" class="btn-link" @click="toggleAccountDetails(props.row)">
                <span class="account-text">
                  <span class="child-toggler-icon">
                    <i class="fas fa-angle-down" v-if="detailsDisplayed.includes(props.row.accountId)" ></i>
                    <i class="fas fa-angle-right" v-else></i>
                  </span>
                  {{props.row.accountCustomName.toLowerCase() || "-"}}
                </span>
              </button>
            </span>
            <span slot="trust" slot-scope="props">
              <span v-if="props.row.components">
                <button title="Open Service/Trust Details" class="btn-link" @click="toggleAccountDetails(props.row, 'Service')">
                  <i v-if="props.row.components[0].trustOutcomeScore === 0" class="fas fa-check-circle" style="color: #2bc275; margin-right: 4px"/>
                  <i v-else-if="props.row.components[0].trustOutcomeScore > 0" class="fas fa-exclamation-circle" style="color: #d74e26; margin-right: 4px"/>
                  <i v-else class="far fa-question-circle" title="Score N/A or Multiple Services exist"/>
                  <span class="account-text">
                    {{ props.row.components[0].trustOutcomeScore }}
                  </span>
                </button>
              </span>
              <span v-else>
                <button title="Open Service/Trust Details" class="btn-link" @click="toggleAccountDetails(props.row, 'Service')">
                  <span class="account-text">
                    <i class="far fa-question-circle" />
                  </span>
                </button>
              </span>
            </span>
            <span slot="costEfficiency" slot-scope="props">
               <button title="Open Billing Insights" class="btn-link" @click="toggleAccountDetails(props.row, 'Billing')">
                <span class="account-text">
                  {{parseAccountCostEfficiency(props.row)}}
                </span>
              </button>
            </span>
            <span slot="environment" slot-scope="props">
              <span>{{props.row.environment.toUpperCase() }}</span>
            </span>
            <template v-slot:accountId="props">
              <button class="btn-link" title="Click to copy Id" @click="copyText(props.row.accountId)">
                <span class="account-text">
                  {{props.row.accountId}}
                </span>
              </button>
            </template>
            <span slot="eligibleRoles" slot-scope="props">
              <span class="available-roles" :title="parseEligibleRoles(props.row, true)">{{parseEligibleRoles(props.row, true).replaceAll("-Eligible", "")}}</span>
            </span>
            <span slot="availableRoles" slot-scope="props">
              <span class="available-roles" :title="parseAvailableRoles(props.row, true)">{{parseAvailableRoles(props.row, true)}}</span>
            </span>
            <span slot="action" slot-scope="props">
              <!-- <button class="access-link" @click="accessActionClick(props.row)"  v-on:click.middle="accessActionClick(props.row)"
                :href="parseLoginUrl(props.row)">
                  Launch
              </button> -->
              <span :title="disabledLaunchTitle">
                <button title="Launch AWS Console" class="btn-link btn-enlarge" :disabled="parseAvailableRoles(props.row).length === 0" @click="showAccessAccountModal(props.row)">
                  <i class="fab fa-aws fa-lg"/>
                </button>
              </span>
              <button title="Activate JIT role" class="btn-link btn-enlarge" :disabled="parseEligibleRoles(props.row).length === 0" @click="raisePIMRequest(props.row)">
                <i class="fa-lg">
                  <img v-if="parseEligibleRoles(props.row).length === 0" style="width: 21px; height: 100%; font-size: 1.33333em; line-height: .75em; vertical-align: -.0667em; filter: grayscale(100%)" src="../assets/images/azure-ad-pim.svg" alt="PIM"/>
                  <img v-else style="width: 21px; height: 100%; font-size: 1.33333em; line-height: .75em; vertical-align: -.0667em;" src="../assets/images/azure-ad-pim.svg" alt="PIM"/>
                </i>
              </button>
              <button title="Cloud Support Request" class="btn-link btn-enlarge" @click="showGenericCloudSupportRequestModal(props.row)">
                <i class="fas fa-tools"/>
              </button>
              <button title="Account Closure Request" class="btn-link btn-enlarge" @click="openCommercialAccountClosureForm">
                <i class="fas fa-trash"/>
              </button>
              <em v-if="getHasAdminRole && !hasValidAppId(props.row)" class="fa fa-exclamation app-id-notice" 
                :title="'Invalid App ID ('+props.row.azureAppId+')'"></em>
            </span>
            <template v-slot:child_row="props">
              <account-details
                  :data="props.row"
                  :initial-tab="initialDetailTab"
              />
            </template>
          </v-client-table>
          <div v-if="isToastVisible" class="toast">
            {{ toastMessage }}
          </div>
        </div>
      </div>
    </div>
    <confirm-access-account-modal
      v-if="displayAccessAccountModal"
      :account="accessAccount"
      @close="closeAccessAccountModal"
    />
    <p-i-m-request-modal
        v-if="displayPIMRequestModal"
        :account="accessAccount"
        @close="closePIMRequestModal"
    />
    <transition name="modal">
      <generic-cloud-support-request-modal
          v-if="displayGenericCloudSupportRequestModal"
          :account="fedRAMPAccount"
          @close="closeGenericCloudSupportRequestModal"
      />
    </transition>
  </div>
</template>

<script>
import api from "@/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import util from "@/util";
import AccountDetails from "@/components/AccountDetailsV2.vue";
import * as C from "../constants.js";
import ConfirmAccessAccountModal from '@/components/modal/ConfirmAccessAccountModal.vue'
import PIMRequestModal from "@/components/modal/PimRequestModal.vue";
import GenericCloudSupportRequestModal from "@/components/modal/GenericCloudSupportRequestModal.vue";

export default {
  name: "myFedrampAccountsTableV2",
  components: {
    GenericCloudSupportRequestModal,
    PIMRequestModal,
    AccountDetails,
    ConfirmAccessAccountModal
  },
  computed: {
    ...mapGetters(["getAppUser", 
      "getFedrampAccounts", 
      "getCacheDataDate",
      "getTokenData",
      "getHasAdminRole"]),
    rows: {
      get: function () {
        return this.getFedrampAccounts;
      },
      set: function (newValue) {
        this.setFedrampAccounts(newValue);
      },
    }
  },
  data: function () {
    return {
      C,
      accountsFiltered: true,
      columns: ["favorite", "accountCustomName", "trust", "costEfficiency", "environment",  "accountId", "eligibleRoles", "availableRoles", "action"],
      options: {
        customFilters: [
          {
            name: "search",
            callback: function (row, query) {
              let q = query.toLowerCase();
              let name = row.accountCustomName.toLowerCase();
              let id = row.accountId.toLowerCase();
              return name.includes(q) || id.includes(q);
            },
          },
        ],
        skin: "table",
        editableColumns: ["favorite"],
        headings: {
          favorite: "*",
          accountCustomName: "Name",
          environment: "Environment",
          accountId: "Account ID",
          trust: "Trust Outcomes",
          costEfficiency: "Cost Efficiency",
          eligibleRoles: "Eligible Roles",
          availableRoles: "Active Roles",
          action: "Actions",
        },
        uniqueKey: "accountId",
        sortable: ["favorite", "accountCustomName", "accountId", "trust", "costEfficiency", "environment"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No FedRAMP accounts to display",
        },
        headingsTooltips: {
          favorite : "Click to sort. Shift+Click to multi-sort.",
          accountId : "Click to sort. Shift+Click to multi-sort.",
          accountCustomName : "Click to sort. Shift+Click to multi-sort.",
          trust: "Trust Issues for the associated service filtered by Trust Outcomes. Click to sort. Shift+Click to multi-sort.",
          eligibleRoles: "Roles available to raise PIM",
          availableRoles: "Roles available for Launch"
        },
        perPageValues: [10,25,50],
        showChildRowToggler: false
      },
      isLoading: false,
      detailsDisplayed:[],
      initialDetailTab: "General",
      displayAccessAccountModal: false,
      displayPIMRequestModal: false,
      accessAccount: null,
      disabledLaunchTitle: "You need at least one active JIT role",
      fedRAMPAccount: null,
      displayGenericCloudSupportRequestModal: null,
      isToastVisible: false,
      toastMessage: ''
    };
  },
  created() {
    
  },
  methods: {
    ...mapMutations(["setFedrampAccounts"]),
    ...mapActions(["addAlert", "loadAccounts"]),
    async setFavorite(val) {
      val.favorite = !val.favorite;
      try {
        if (val.favorite) {
          await api.saveMyFavorite({
            accountId: val.accountId,
          });
          this.trackEvent(C.ACTION_TYPES.ADD_FAVORITE, this.$router.currentRoute.path+"?view=my-fedRAMP-accounts",  `account_id=${val.accountId} name=${val.accountCustomName}`);
        } else {
          await api.deleteMyFavorite(val.accountId);
          this.trackEvent(C.ACTION_TYPES.REMOVE_FAVORITE, this.$router.currentRoute.path+"?view=my-fedRAMP-accounts",  `account_id=${val.accountId} name=${val.accountCustomName}`);
        }
      } catch (err) {
        val.favorite = !val.favorite;
        console.error("Error saving favorite", err);
        this.addAlert(
          util.createAlert(0,
            "Error modifying favorite. Please try again or contact support.",
            C.ALERT_CONTEXT.DANGER
          )
        );
      }
    },
    parseDate(date) {
      return util.parseDate(date)
    },
    copyText(text){
      navigator.clipboard.writeText(text);
      this.showToast("Copied to clipboard");
    },
    showToast(message) {
      this.toastMessage = message;
      this.isToastVisible = true;

      setTimeout(() => {
        this.isToastVisible = false;
      }, 3000);
    },
    accessActionClick(item) {      
      this.trackEvent(C.ACTION_TYPES.ACCESS_LINK, this.$router.currentRoute.path+"?view=my-fedRAMP-accounts",  `account_id=${item.accountId} name=${item.accountCustomName}`);
    },   
    parseRole(account, trim) {
      return  util.parseRole(account, trim)
      // return roles === "" ? "None" : roles
    },
    isRoleEmpty(account, trim){
      let roles = util.parseRole(account, trim)
      return roles === ""
    },
    parseAvailableRoles(account, join){
      let roles = util.parseRole(account).split(", ")
      let availableRoles = roles.filter((role) => !role.includes("Eligible"))
      if(join){
        return availableRoles.length>0 ? availableRoles.join(", ") : "None"
      }else {
        return availableRoles
      }
    },
    parseEligibleRoles(account, join){
      let roles = util.parseRole(account).split(", ")
      let eligibleRoles = roles.filter((role) => role.includes("Eligible"))
      if(join){
        return eligibleRoles.length>0 ? eligibleRoles.join(", ") : "None"
      }else {
        return eligibleRoles
      }
    },
    hasValidAppId(item) {
      return util.hasValidAppId(item)
    },
    parseAccountCostEfficiency(item){
      const percentage = item.billingInsight?.CALC_EFF * 100;
      if(isNaN(percentage)){
        return '-'
      }
      if (percentage % 1 === 0) {
        return percentage.toFixed(0) + "%";
      } else {
        return percentage.toFixed(2) + "%";
      }
    },
    async reloadAccounts() {
      await this.loadAccounts()
    },
    async toggleAccountDetails(row, detailTab) {
      this.initialDetailTab = detailTab ?? "General";
      
      if (this.detailsDisplayed.includes(row.accountId)) {
        this.detailsDisplayed.splice(this.detailsDisplayed.indexOf(row.accountId), 1)
        this.trackEvent(C.ACTION_TYPES.HIDE_VIEW_DETAIL, this.$router.currentRoute.path+"?view=my-fedRAMP-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      } else {
        this.detailsDisplayed.push(row.accountId)
        this.trackEvent(C.ACTION_TYPES.SHOW_VIEW_DETAIL, this.$router.currentRoute.path+"?view=my-fedRAMP-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      }

      this.$refs.accountTable.toggleChildRow(row.accountId);
    },
    showAccessAccountModal(item) {   
      this.trackEvent(C.ACTION_TYPES.ACCESS_FEDRAMP_MODAL_LINK, this.$router.currentRoute.path+"?view=my-fedRAMP-accounts", `account_id=${item.accountId} name=${item.accountCustomName}`);
      this.displayAccessAccountModal = true
      this.accessAccount = item
    },
    closeAccessAccountModal() {
      this.displayAccessAccountModal = false
      this.accessAccount = null
    },
    async raisePIMRequest(item) {
      if(item.azureAppData.filter((ele)=> ele.displayName.includes("-Eligible")).length === 1){
        let pimURL = ""
        let directoryDomain = item.environment === 'PRD' ? '@adskeng.onmicrosoft.com' : '@adskengpp.onmicrosoft.com'
        try{
          let requestedPIMAccDisplayName = item.azureAppData[0].displayName.replace("-Eligible", "")

          // Getting the obj id for the group using azure graph query, so that we can redirect user to pim link
          let azureSgResponse = await api.getAccountAzureInfo(item.accountId, {"profile": item.profile, "env": item.environment, "role": requestedPIMAccDisplayName.split('-')[3]})
          let azureSgObjectId = azureSgResponse.data.data[0].id

          pimURL = `https://portal.azure.com/${directoryDomain}#view/Microsoft_Azure_PIMCommon/ResourceMenuBlade/~/MyActions/resourceId/${azureSgObjectId}/resourceType/Security/provider/aadgroup/resourceDisplayName/${requestedPIMAccDisplayName}/resourceExternalId/${azureSgObjectId}`
        } catch (e){
          console.log("Error in PIM Request", e)
          pimURL = `https://portal.azure.com/${directoryDomain}#view/Microsoft_Azure_PIMCommon/ActivationMenuBlade/~/aadmigratedroles`
        }
        window.open(pimURL, '_blank')
      } else {
        this.displayPIMRequestModal = true
        this.accessAccount = item
      }
      this.trackEvent(C.ACTION_TYPES.RAISE_PIM_LINK, this.$router.currentRoute.path);
    },
    closePIMRequestModal() {
      this.displayPIMRequestModal = false
    },
  showGenericCloudSupportRequestModal(item) {
    this.trackEvent(C.ACTION_TYPES.NON_STANDARD_REQUEST_LINK, this.$router.currentRoute.path+"?view=my-fedramp-accounts", `account_id=${item.accountId} name=${item.accountCustomName}`);
    this.displayGenericCloudSupportRequestModal = true
    this.fedRAMPAccount = item
  },
  closeGenericCloudSupportRequestModal(){
    this.displayGenericCloudSupportRequestModal = false
    this.fedRAMPAccount = null
  },
  openCommercialAccountClosureForm(){
    window.open(C.COMMERCIAL_ACCOUNT_CLOSURE_URL, '_blank')
  }
  },
};
</script>
<style scoped>
.account-text{
  color:#5a5a5a;
  display:inline-flex;
}
.child-toggler-icon{
  font-size: 14px;
  margin-right: 8px;
  color: #5f60ff;
}
.cache-info-text {
  font-size: 14px;
}
.account-help-text {
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
.accounts-table-container {
  margin-top: 5px;
  font-size: 14px;
}
.access-link {
  font-weight: bold;
}
.app-id-notice {
  color: red;
  font-size: 14px;
}
.sync-btn {
  border: none;
  background: transparent;
  color: #5f60ff;
  outline: none;
  margin-left: 5px;
  font-size: 14px;
}
.sync-btn:hover {
  color: #0056b3;
}

</style>
